.navbar-bg{
  display: block;
}

.navbar-mob{
  display: none;
}
.nav-mob-view{
  background-color: #07096f;
  color: #fff;
}
.nav-mob-view li{
  color: #fff !important;
}
body {
    font-family: "Freehand";
    background: #ffffff; /* fallback for old browsers */
  }

  body::-webkit-scrollbar{
	  display: none ;
	}


 @font-face {
  font-family: "Freehand";
  src: local("FreehandLight"), url("./Fonts/Freehand.ttf") format("truetype");
 }

 @font-face {
  font-family: "BerlinSansMain";
  src: local("BerlinSansMainFB"), url("./Fonts/BRLNSDB.TTF");
 }

 @font-face {
  font-family: "MisterEarl";
  src: local("MisterEarlBTRegular"),url("./Fonts/MisterEarlBTRegular.TTF");
 }

 @font-face {
  font-family: "BerhandTango";
  src: local("BerhandTango"),url("./Fonts/BernhandTango.ttf");
 }

 @font-face {
  font-family: "Calligraph421";
  src: local("Calligraph421BT"),url("./Fonts/Calligraph421BT.TTF");
 }

 @font-face {
  font-family: "dombold";
  src: local("domboldBT"),url("./Fonts/domboldBT.ttf");
 }
 @font-face {
  font-family: "GilSans";
  src: local("GilSansMTCondBold"),url("./Fonts/GilSansMTCondBold.TTF");
 }
 @font-face {
  font-family: "GilSansCond";
  src: local("GilSansMTCondensed"),url("./Fonts/GilSansMTCondensed.TTF");
 }
 @font-face {
  font-family: "Harngton";
  src: local("Harngton"),url("./Fonts/HARNGTON.TTF");
 }

 @font-face {
  font-family: "hobo";
  src: local("HoboStd"),url("./Fonts/HoboStd.otf");
 }
 @font-face {
  font-family: "JohnHandy";
  src: local("JohnHandyLET"),url("./Fonts/JohnHandyLET.TTF");
 }
 @font-face {
  font-family: "ParkAvenue";
  src: local("ParkAvenue"),url("./Fonts/ParkAvenue.TTF");
 }
 @font-face {
  font-family: "TektonPro";
  src: local("TketonPro-Bold"),url("./Fonts/TektonPro-Bold.otf");
 }
 @font-face {
  font-family: "TektonProC";
  src: local("TketonPro-BoldCond"),url("./Fonts/TektonPro-BoldCond.otf");
 }

 .pub-image {
  width: 200px;
  height: 200px;
  overflow: hidden;
  margin: 0 auto;
  border-radius: 30px;
}

.pub-image img {
  width: 100%;
  transition: 0.5s all ease-in-out;
}

.pub-image:hover img {
  transform: scale(1.2);
}

 .geeks {
  width: 100%;
  object-fit: contain;
  overflow: hidden;
  margin: 0 auto;
}

.geeks img {
  width: 100%;
  transition: 0.5s all ease-in-out;
}

.geeks:hover img {
  transform: scale(1.2);
}
.top-bar{
  background-color: rgb(153, 234, 252);
  width: 100%;
  height:50px;
}
.div-header{
  background-color: #01000a;
  width: 100%;
  border-radius: 10px;
}
.home-sections{
  padding-left: 10%;
  padding-right: 10%;
}
.header-image{
  width: 150px;
  border-radius: 3px;
  transition: width 1s ease;
  padding: 2px 0;
  /*
  border-left: 5px solid #0f5fa3;
  position: relative;
  text-transform: uppercase;
  margin-bottom: 25px;
  margin-top: 65px; */
  }
  .header-image img{

    margin: -300px;
  }
  
  .header-image:hover {
    width: 230px;
  }

  .section-title{
    background-color: gold;
    font-family: "Young Serif";
    color: rgba(22, 22, 117, 0.941) !important;
    border-radius: 20px;
  }

  .section-title .new-icon{
    width: 80px;
  }
  .line-header{
  font-size: 28px;
  font-weight: 800;
  }

  .style1 { 
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.btn-wrap:hover {
  background-color : #07096f;
  color: white;
}

.list-sty{
  background-color: black;
  color: white;
  border-radius: 30px;
  padding: 10px;
  margin-bottom: 5px;
  width: 180px;
}

/*------------------ Publication CSS --------------*/

.card-title{
 font-family: "Black Ops One";


}

.card-text{
  font-size: 12px;
 
 }


.truncate{
font-family: "Verdana" ;
}

/*-----------     ------------- */
.img-world{
  width: 300px;
}

.courses-banner-img{
  margin-top: -40%;
}

.courses-info h4{
  font-family: "BerlinSansMain";
}

.courses-info-text li{
  font-family: "Freehand";
  font-size: 22px;
  color: rgb(255, 255, 255);
}
/*Courses Accordion Section
--------------------------------*/
.accordion-item .accordion-button{
 background-color: #030000;
 border-radius: 8px;
 color: #fff;
 font-family: "TektonPro";
 font-size: 16px;
  width: 100%;

}

.accordion-body {

 color: #fff;

}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 7; /* number of lines to show */
          line-clamp: 6;
  -webkit-box-orient: vertical;
}

.headertruncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
          line-clamp: 2;
  -webkit-box-orient: vertical;
}



.card-highlight:hover .card-title { background-color: #07096f;   color: #ffffff !important;}

/* About
--------------------------------*/

.abt-img {
  border-radius: 20px;
}

/* Subscription Section
--------------------------------*/

.subscription-details p{
margin-bottom: 5px;
}

.subscription-section{
  font-family: "Young Serif";
}

/* Contact Section
--------------------------------*/
#contact {
  background: #ffffff;
  color:#07096f;
}

#contact .info {
  color: #07096f;
}

#contact .info i {
  font-size: 32px;
  color: #07096f;
  float: left;
}

#contact .info p {
  padding: 0 0 10px 50px;
  margin-bottom: 20px;
  line-height: 22px;
  font-size: 14px;
  color:#01000a;
}

#contact .info .email p {
  padding-top: 5px;
}

#contact .social-links {
  padding-bottom: 20px;
}

#contact .social-links a {
  font-size: 18px;
  display: inline-block;
  color : #07096f;
  background : #fff;

  line-height: 1;
  padding: 6px 0 8px 0;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}


#contact .social-links a:hover {
  background: #07096f;
  color: #fff;

}

#contact .form #sendmessage {
  color: #71c55d;
  border: 1px solid #71c55d;
  display: none;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#contact .form #errormessage {
  color: red;
  display: none;
  border: 1px solid red;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#contact .form #sendmessage.show, #contact .form #errormessage.show, #contact .form .show {
  display: block;
}

#contact .form .validation {
  color: red;
  display: none;
  margin: 0 0 20px;
  font-weight: 400;
  font-size: 13px;
}

#contact .form input, #contact .form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  padding: 12px 10px;
  line-height: 1;
}

#contact .form button[type="submit"] {
  background: #07096f;
  border: 0;
  padding: 8px 12px;
  color: #fff;
  transition: 0.4s;
}
.form-control{
  
  color: #01000a !important;
}
#contact .form button[type="submit"]:hover {
  background: #07096f;
}
.contact-sec{
padding: 6%;

font-family: "hobo";
}

#contact .form-group {
  border: 2px solid #07096f;
  border-radius: 2px;
}
.contact-view .info{
  font-family: "Oswald";
}
.footer-main{
  background-color: rgba(22, 22, 117, 0.941) !important;
  color: gold !important;
  padding-top: 2%;
  padding-left: 6%;
  padding-right: 6%;
  font-family: "Young Serif";
}
.footer-main p{
  color: gold !important;
 
}
.footer-main .contact-view{
  padding:30px;
  color: white !important;
}
.contact-view h3{
  color: white;
  font-family: "Young Serif";
}
.footer-main a:link{
  color: gold !important;
  padding: 14px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
 } 
 .footer-main a{
  font-family: "Courgette";
  font-size: 16px;
  color: gold !important;
 }


@media only screen and (max-width: 580px) {
  .home-sections{
    padding-left: 0%;
    padding-right: 0%;
  }

  .section-title h3{
    font-size: 14px !important;
  }
  .section-title .new-icon{
    width: 50px;
  }
  .courses-banner-img{
    margin-top: -45%;
  }
  .course-level-section h1{
    font-size: 18px;
  }
  
}

@media only screen and (max-width: 480px) {
  .home-sections{
    padding-left: 0%;
    padding-right: 0%;
  }
.courses-banner-img{
  margin-top: -46%;
}


}

@media only screen and (max-width: 390px) {

  .courses-banner-img{
    margin-top: -50%;
  }
 
}




@media only screen and (max-width: 1350px) {

.card-title{
  font-family: "Black Ops One";
  font-size: 12px;
 
 }


 .truncate{
  font-size: 8px;
  }
  
}

@media only screen and (max-width: 992px) {

.navbar-bg{
  display: none;
}
.navbar-mob{
  display: block;
}
}